import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const GuestRoute = ({ component: Component, ...rest }) => {
  const [redirect, setRedirect] = useState(null)
  const Auth = useSelector((state) => ({
    redir: state.Auth.redirect,
    isAuthenticated: state.Auth.isAuthenticated,
    isLoading: state.Auth.isLoading,
    redirect: state.router?.location?.pathname,
    redirectPathname: state.Auth.redirect?.pathname || ''
  }))

  useEffect(() => {
    if (!Auth.isLoading) {
      if (typeof Auth.redir === 'string') {
        setRedirect(Auth.redir)
      } else if (Auth.redirectPathname) {
        if (['/', '/auth'].includes(Auth.redirectPathname)) {
          setRedirect('/galaxy')
        } else {
          setRedirect(Auth.redirectPathname)
        }    
      } else if (['/', '/auth'].includes(Auth.redirect)) {
        setRedirect('/galaxy')
      } else {
        setRedirect(Auth.redirect)
      }
    }
  }, [Auth.isAuthenticated, Auth.redir])

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.isAuthenticated ?
          redirect ?
            <Redirect to={redirect} />
            :
            <Redirect to='/galaxy' />
          :
          <Component {...props}/>
      }
    />
  )
}

GuestRoute.propTypes = {
  component: PropTypes.object
}

export default GuestRoute