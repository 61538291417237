import loadable from '@loadable/component'
import { Layout } from 'antd'
import React from 'react'
import { useLocation } from 'react-router-dom'

const { Content } = Layout

const Header = loadable(() => import('@components/header'))
const Mobile = loadable(() => import('@components/header/mobile'))
const Navbar = loadable(() => import('@components/navbar'))

export const BaseLayout = ({ children }) => {
  const { pathname } = useLocation()
  
  return (
    pathname === '/auth'
      ? children
      :
      <Layout>
        <Header />
        <Mobile />
        <Layout className="h-full">
          <Navbar />
          <Content id="main">
            <div>
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
  )
}

BaseLayout.propTypes = {
  // children: PropTypes.array.isRequired
}

export default (BaseLayout)