import { Layout, notification, Spin } from 'antd'
import PropTypes, { oneOfType } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'

import { resetRedirect } from '../store/actions'

const { Content } = Layout

const PrivateRoute = ({ component: Component, only, except, extraProps, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [access, setAccess] = useState(true)
  const [accessLoading, setAccessLoading] = useState(true)
  const [lastAuthorizedPath, setLastAuthorizedPath] = useState(null)

  const Auth = useSelector((state) => ({
    isAuthenticated: state.Auth.isAuthenticated,
    isLoading: state.Auth.isLoading,
    permissions: state.Auth.user?.permissions || [],
    pathname: state.router?.location?.pathname,
    forceRedirect: state.Auth?.forceRedirect,
    redirect: state.Auth?.redirect
  }))

  useEffect(() => {
    // Only check access if authenticated
    if (Auth.isAuthenticated) {
      if (
        // Check access to current route
        (!except || !except.some(perm => Auth.permissions.includes(perm)))
        && (!only || only.some(perm => Auth.permissions.includes(perm)))
      ) {
        // Save current path for hypothetical future redirect, then grant access
        setLastAuthorizedPath(rest.location?.pathname)
        setAccess(true)
      } else {
        // Deny access
        notification.error({ message: 'Accès interdit' })
        setAccess(false)
      }
      setAccessLoading(false)
    } else {
      if (!Auth.isLoading) {
        setAccessLoading(false)
      }
    }
  }, [Component, Auth.pathname, Auth.isAuthenticated, Auth.isLoading])

  useEffect(() => {
    if (Auth.forceRedirect && Auth.redirect) {
      const to = Auth.redirect
      dispatch(resetRedirect())
      history.push(to)
    }
  }, [Auth.forceRedirect, Auth.redirect])

  return (
    <Route
      {...rest}
      render={(props) => accessLoading
        ? (
          <Layout>
            <Content style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spin />
            </Content>
          </Layout>
        )
        : Auth.isAuthenticated
          ? access
            ? (
              <Layout>
                <Component {...props} {...extraProps} />
              </Layout>
            )
            : <Redirect to={{ pathname: lastAuthorizedPath || '/galaxy' }} />
          : <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  extraProps: PropTypes.object,
  only: PropTypes.array,
  except: PropTypes.array,
  location: PropTypes.object
}

export default PrivateRoute