import requester from './requester'

const get = (request, setData) => requester(request, setData)

const post = (request, setData) => requester({ ...request, $method: 'POST' }, setData)

const put = (request, setData) => requester({ ...request, $method: 'PUT' }, setData)

const remove = (request, setData) => requester({ ...request, $method: 'DELETE' }, setData)

export default {
  get,
  post,
  put,
  delete: remove
}
