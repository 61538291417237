import React from 'react'
import { render } from 'react-dom'

import Store from './store/index'
import App from './App'
import './i18n'

render(
  <App store={Store} />,
  document.getElementById('root')
)
