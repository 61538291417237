import { I18N_LANGUAGE } from '@config'
import { AdjustmentsIcon, BriefcaseIcon, ChartPieIcon, CogIcon, CreditCardIcon, DocumentReportIcon, LibraryIcon, PresentationChartLineIcon, ShieldCheckIcon, ShoppingCartIcon, SparklesIcon, UserAddIcon } from '@heroicons/react/solid'
import loadable from '@loadable/component'
import GuestRoute from '@routes/GuestRoute'
import PrivateRoute from '@routes/PrivateRoute'
import { ConfigProvider, message, notification } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import frFR from 'antd/lib/locale-provider/fr_FR'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { createContext, Suspense, useEffect, useState } from 'react'
import HttpsRedirect from 'react-https-redirect'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import BaseLayout from './BaseLayout'
import './css/tailwind.css'
import './css/antd.less'
import './css/galaxy.css'
import './css/nprogress.css'
import { CheckAuthentication } from './store/middlewares'


const Galaxy = loadable(() => import('./apps/galaxy'))
const SubmenuGalaxy = loadable(() => import('./apps/galaxy/menu'))

const Accounting = loadable(() => import('./apps/accounting'))
const SubmenuAccounting = loadable(() => import('./apps/accounting/menu'))

const Change = loadable(() => import('./apps/change'))
const SubmenuChange = loadable(() => import('./apps/change/menu'))

const Recruitment = loadable(() => import('./apps/recruitment'))
const SubmenuRecruitment = loadable(() => import('./apps/recruitment/menu'))

const Training = loadable(() => import('./apps/training'))
const SubmenuTraining = loadable(() => import('./apps/training/menu'))

const Admin = loadable(() => import('./apps/admin'))
const SubmenuAdmin = loadable(() => import('./apps/admin/menu'))

const Assessment = loadable(() => import('./apps/assessment'))
const SubmenuAssessment = loadable(() => import('./apps/assessment/menu'))

const Sales = loadable(() => import('./apps/sales'))
const SubmenuSales = loadable(() => import('./apps/sales/menu'))

const Academy = loadable(() => import('./apps/academy'))
const SubmenuAcademy = loadable(() => import('./apps/academy/menu'))

const Account = loadable(() => import('./apps/account'))
const SubmenuAccount = loadable(() => import('./apps/account/menu'))

const HR = loadable(() => import('./apps/hr'))
const SubmenuHR = loadable(() => import('./apps/hr/menu'))

const Marketing = loadable(() => import('./apps/marketing'))
const SubmenuMarketing = loadable(() => import('./apps/marketing/menu'))

const Audit = loadable(() => import('./apps/audit'))
const SubmenuAudit = loadable(() => import('./apps/audit/menu'))

const Login = loadable(() => import('./components/auth'))

/*
    Permissions warning:

    - The props 'only' and 'except' can be passed as props to the top level route in App.
    - It will determine which apps the current user can access, as well as which items are listed in the apps menu.
    - For both only and except, empty arrays are considered as valid. If no restriction is wanted, the prop should be omitted.
    - except always takes priority over only.
    
    Example: 
    {
        admin: {
            only: ['developer'],    // Only grant access to people with the 'developer' permission
            except: ['admin']       // Deny access to anyone with the 'admin' permission, even if they have the 'developer' permission
        }
    }
*/
export const applications = [{
  title: 'Galaxy',
  to: '/galaxy',
  key: 'galaxy',
  icon: <SparklesIcon className="h-6 w-6" />,
  component: Galaxy,
  submenu: <SubmenuGalaxy />
}, {
  title: 'Recrutement',
  to: '/recruitment',
  key: 'recruitment',
  icon: <UserAddIcon className="h-6 w-6" />,
  component: Recruitment,
  submenu: <SubmenuRecruitment />
}, {
  title: 'Formation',
  to: '/training',
  key: 'training',
  icon: <PresentationChartLineIcon className="h-6 w-6" />,
  component: Training,
  submenu: <SubmenuTraining />
}, {
  title: 'Conseil',
  to: '/change',
  key: 'change',
  icon: <AdjustmentsIcon className="h-6 w-6" />,
  component: Change,
  submenu: <SubmenuChange />
}, {
  title: 'Audit',
  to: '/audit',
  key: 'audit',
  icon: <DocumentReportIcon className="h-6 w-6" />,
  component: Audit,
  submenu: <SubmenuAudit />
}, {
  title: 'Assessment',
  to: '/assessment',
  key: 'assessment',
  icon: <ChartPieIcon className="h-6 w-6" />,
  component: Assessment,
  submenu: <SubmenuAssessment />
}, {
  title: 'Facturation',
  to: '/accounting',
  key: 'accounting',
  icon: <CreditCardIcon className="h-6 w-6" />,
  except: ['staffer.sales'],
  component: Accounting,
  submenu: <SubmenuAccounting />
}, {
  title: 'RH',
  to: '/hr',
  key: 'hr',
  icon: <ShieldCheckIcon className="h-6 w-6" />,
  component: HR,
  submenu: <SubmenuHR />
}, {
  title: 'Academy',
  to: '/academy',
  key: 'academy',
  icon: <LibraryIcon className="h-6 w-6" />,
  only: ['developer', 'sales'],
  component: Academy,
  submenu: <SubmenuAcademy />
}, {
  title: 'Administration',
  to: '/admin',
  key: 'admin',
  icon: <ShieldCheckIcon className="h-6 w-6" />,
  only: ['developer', 'admin'],
  component: Admin,
  submenu: <SubmenuAdmin />
}, {
  title: 'Commerce',
  to: '/sales',
  key: 'sales',
  icon: <BriefcaseIcon className="h-6 w-6" />,
  only: ['developer', 'sales'],
  component: Sales,
  submenu: <SubmenuSales />
}, {
  title: 'Marketing',
  to: '/marketing',
  key: 'marketing',
  icon: <ShoppingCartIcon className="h-6 w-6" />,
  only: ['marketing'],
  component: Marketing,
  submenu: <SubmenuMarketing />
}, {
  title: 'Paramètres',
  to: '/account',
  key: 'account',
  icon: <CogIcon className="h-6 w-6" />,
  component: Account,
  submenu: <SubmenuAccount />,
  hidden: true
}
]
/*
    End of permissions warning
*/

const antdLocales = {
  fr: frFR,
  en: enUS
}

export const AppContext = createContext(null)

export const App = ({ store }) => {
  const locale = localStorage.getItem(I18N_LANGUAGE)
  const [antdLocale, setAntdLocale] = useState(antdLocales[locale] || 'fr')

  message.config({
    top: 11,
    duration: 2
  })
  notification.config({
    placement: 'topRight',
    bottom: 0,
    duration: 3
  })

  useEffect(() => {
    CheckAuthentication()
  }, [])

  const updateAntdLocale = code => {
    if (antdLocales[code]) {
      setAntdLocale(antdLocales[code])
    }
  }

  return (
    // todo: remplacer par spinner propre
    <Suspense fallback='loading'>
      <div id='app'>
        <HttpsRedirect>
          <ConfigProvider locale={antdLocale}>
            <Provider store={store}>
              <AppContext.Provider value={{ updateAntdLocale }}>
                <Router>
                  <BaseLayout>
                    <Switch>
                      <GuestRoute
                        exact
                        path='/auth'
                        component={Login}
                      />
                      {applications.map(app => (
                        <PrivateRoute
                          key={app.key}
                          path={app.to}
                          component={app.component}
                          only={app.only}
                          except={app.except}
                        />
                      ))}
                      {/* <Redirect from='/' to='/auth' /> */}
                      <Redirect from="/consulting/audits/:id" to="/change/scans/:id" />
                      <Redirect from='/' to='/galaxy' />
                    </Switch>
                  </BaseLayout>
                </Router>

              </AppContext.Provider>
            </Provider>
          </ConfigProvider>
        </HttpsRedirect>
      </div>
    </Suspense>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired
}

export default (App)