const GET_ALL = {
  loading: '@api/LOADING_GET_ALL',
  success: '@api/SUCCESS_GET_ALL',
  error: '@api/ERROR_GET_ALL'
}
const GET = {
  loading: '@api/LOADING_GET',
  success: '@api/SUCCESS_GET',
  error: '@api/ERROR_GET'
}

export default {
  GET_ALL,
  GET
  // loading,
  // success,
  // error
}
