export const initialState = {
  isAuthenticated: false,
  isLoading: true,
  user: {},
  authenticator: null,
  redirect: null,
  forceRedirect: false
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        redirect: action.payload
      }
    case 'SET_UNAUTHENTICATED':
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        authenticator: null,
        redirect: action.payload
      }
    case 'AUTH_AUTHENTICATOR':
      return {
        ...state,
        authenticator: action.payload,
        isLoading: false
      }
    case 'SET_USER':
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      }
    case 'SET_AUTHENTICATOR':
      return {
        ...state,
        user: {
          ...state.user,
          authenticator: {
            ...state.user.authenticator,
            ...action.payload
          }
        }
      }
    case 'LOADING_USER':
      return {
        ...state,
        isLoading: true
      }

    case 'SET_REDIRECT':
      return {
        ...state,
        forceRedirect: true,
        redirect: action.payload
      }

    case 'RESET_REDIRECT':
      return {
        ...state,
        forceRedirect: false,
        redirect: null
      }

    default:
      return state
  }
}